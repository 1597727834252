export type VideoProvider = 'youtube';

export const defaultVideoRatio = 16 / 9;

export interface VideoEmbedInfo {
  videoId: string;
  provider?: VideoProvider;
  aspectRatio?: number;
  startTime?: number;
  endTime?: number;
}
