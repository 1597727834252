import { Injectable } from '@angular/core';
import { YouTubePlayer } from '@angular/youtube-player';

@Injectable({
  providedIn: 'root',
})
export class YoutubePlayerService {
  readonly players: YouTubePlayer[] = [];

  constructor() {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
  }

  register(player: YouTubePlayer): void {
    this.players.push(player);

    player.stateChange.subscribe((state) => {
      if (state.data === YT.PlayerState.PLAYING) {
        this.pauseOtherPlayers(player);
      }
    });
  }

  unregister(player: YouTubePlayer): void {
    this.players.splice(this.players.indexOf(player), 1);
  }

  pauseOtherPlayers(player: YouTubePlayer): void {
    this.players.forEach((otherPlayer) => {
      if (player !== otherPlayer) {
        otherPlayer.pauseVideo();
      }
    });
  }
}
