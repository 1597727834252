import * as i0 from '@angular/core';
import { PLATFORM_ID, Component, ChangeDetectionStrategy, ViewEncapsulation, Inject, Input, Output, ViewChild, NgModule } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subject, BehaviorSubject, of, combineLatest, pipe, Observable, fromEventPattern, merge } from 'rxjs';
import { take, startWith, map, combineLatest as combineLatest$1, skipWhile, scan, distinctUntilChanged, tap, mergeMap, takeUntil, publish, switchMap, withLatestFrom, filter } from 'rxjs/operators';

/// <reference types="youtube" />
const _c0 = ["youtubeContainer"];
const DEFAULT_PLAYER_WIDTH = 640;
const DEFAULT_PLAYER_HEIGHT = 390;
/**
 * Angular component that renders a YouTube player via the YouTube player
 * iframe API.
 * @see https://developers.google.com/youtube/iframe_api_reference
 */
class YouTubePlayer {
  /** YouTube Video ID to view */
  get videoId() {
    return this._videoId.value;
  }
  set videoId(videoId) {
    this._videoId.next(videoId);
  }
  /** Height of video player */
  get height() {
    return this._height.value;
  }
  set height(height) {
    this._height.next(height || DEFAULT_PLAYER_HEIGHT);
  }
  /** Width of video player */
  get width() {
    return this._width.value;
  }
  set width(width) {
    this._width.next(width || DEFAULT_PLAYER_WIDTH);
  }
  /** The moment when the player is supposed to start playing */
  set startSeconds(startSeconds) {
    this._startSeconds.next(startSeconds);
  }
  /** The moment when the player is supposed to stop playing */
  set endSeconds(endSeconds) {
    this._endSeconds.next(endSeconds);
  }
  /** The suggested quality of the player */
  set suggestedQuality(suggestedQuality) {
    this._suggestedQuality.next(suggestedQuality);
  }
  /**
   * Extra parameters used to configure the player. See:
   * https://developers.google.com/youtube/player_parameters.html?playerVersion=HTML5#Parameters
   */
  get playerVars() {
    return this._playerVars.value;
  }
  set playerVars(playerVars) {
    this._playerVars.next(playerVars);
  }
  /** Whether cookies inside the player have been disabled. */
  get disableCookies() {
    return this._disableCookies.value;
  }
  set disableCookies(value) {
    this._disableCookies.next(!!value);
  }
  constructor(_ngZone, platformId) {
    this._ngZone = _ngZone;
    this._youtubeContainer = new Subject();
    this._destroyed = new Subject();
    this._playerChanges = new BehaviorSubject(undefined);
    this._videoId = new BehaviorSubject(undefined);
    this._height = new BehaviorSubject(DEFAULT_PLAYER_HEIGHT);
    this._width = new BehaviorSubject(DEFAULT_PLAYER_WIDTH);
    this._startSeconds = new BehaviorSubject(undefined);
    this._endSeconds = new BehaviorSubject(undefined);
    this._suggestedQuality = new BehaviorSubject(undefined);
    this._playerVars = new BehaviorSubject(undefined);
    this._disableCookies = new BehaviorSubject(false);
    /** Outputs are direct proxies from the player itself. */
    this.ready = this._getLazyEmitter('onReady');
    this.stateChange = this._getLazyEmitter('onStateChange');
    this.error = this._getLazyEmitter('onError');
    this.apiChange = this._getLazyEmitter('onApiChange');
    this.playbackQualityChange = this._getLazyEmitter('onPlaybackQualityChange');
    this.playbackRateChange = this._getLazyEmitter('onPlaybackRateChange');
    this._isBrowser = isPlatformBrowser(platformId);
  }
  ngOnInit() {
    // Don't do anything if we're not in a browser environment.
    if (!this._isBrowser) {
      return;
    }
    let iframeApiAvailableObs = of(true);
    if (!window.YT || !window.YT.Player) {
      if (this.showBeforeIframeApiLoads && (typeof ngDevMode === 'undefined' || ngDevMode)) {
        throw new Error('Namespace YT not found, cannot construct embedded youtube player. ' + 'Please install the YouTube Player API Reference for iframe Embeds: ' + 'https://developers.google.com/youtube/iframe_api_reference');
      }
      const iframeApiAvailableSubject = new Subject();
      this._existingApiReadyCallback = window.onYouTubeIframeAPIReady;
      window.onYouTubeIframeAPIReady = () => {
        if (this._existingApiReadyCallback) {
          this._existingApiReadyCallback();
        }
        this._ngZone.run(() => iframeApiAvailableSubject.next(true));
      };
      iframeApiAvailableObs = iframeApiAvailableSubject.pipe(take(1), startWith(false));
    }
    const hostObservable = this._disableCookies.pipe(map(cookiesDisabled => cookiesDisabled ? 'https://www.youtube-nocookie.com' : undefined));
    // An observable of the currently loaded player.
    const playerObs = createPlayerObservable(this._youtubeContainer, this._videoId, hostObservable, iframeApiAvailableObs, this._width, this._height, this._playerVars, this._ngZone).pipe(tap(player => {
      // Emit this before the `waitUntilReady` call so that we can bind to
      // events that happen as the player is being initialized (e.g. `onReady`).
      this._playerChanges.next(player);
    }), waitUntilReady(player => {
      // Destroy the player if loading was aborted so that we don't end up leaking memory.
      if (!playerIsReady(player)) {
        player.destroy();
      }
    }), takeUntil(this._destroyed), publish());
    // Set up side effects to bind inputs to the player.
    playerObs.subscribe(player => {
      this._player = player;
      if (player && this._pendingPlayerState) {
        this._initializePlayer(player, this._pendingPlayerState);
      }
      this._pendingPlayerState = undefined;
    });
    bindSizeToPlayer(playerObs, this._width, this._height);
    bindSuggestedQualityToPlayer(playerObs, this._suggestedQuality);
    bindCueVideoCall(playerObs, this._videoId, this._startSeconds, this._endSeconds, this._suggestedQuality, this._destroyed);
    // After all of the subscriptions are set up, connect the observable.
    playerObs.connect();
  }
  ngAfterViewInit() {
    this._youtubeContainer.next(this.youtubeContainer.nativeElement);
  }
  ngOnDestroy() {
    if (this._player) {
      this._player.destroy();
      window.onYouTubeIframeAPIReady = this._existingApiReadyCallback;
    }
    this._playerChanges.complete();
    this._videoId.complete();
    this._height.complete();
    this._width.complete();
    this._startSeconds.complete();
    this._endSeconds.complete();
    this._suggestedQuality.complete();
    this._youtubeContainer.complete();
    this._playerVars.complete();
    this._destroyed.next();
    this._destroyed.complete();
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#playVideo */
  playVideo() {
    if (this._player) {
      this._player.playVideo();
    } else {
      this._getPendingState().playbackState = YT.PlayerState.PLAYING;
    }
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#pauseVideo */
  pauseVideo() {
    if (this._player) {
      this._player.pauseVideo();
    } else {
      this._getPendingState().playbackState = YT.PlayerState.PAUSED;
    }
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#stopVideo */
  stopVideo() {
    if (this._player) {
      this._player.stopVideo();
    } else {
      // It seems like YouTube sets the player to CUED when it's stopped.
      this._getPendingState().playbackState = YT.PlayerState.CUED;
    }
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#seekTo */
  seekTo(seconds, allowSeekAhead) {
    if (this._player) {
      this._player.seekTo(seconds, allowSeekAhead);
    } else {
      this._getPendingState().seek = {
        seconds,
        allowSeekAhead
      };
    }
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#mute */
  mute() {
    if (this._player) {
      this._player.mute();
    } else {
      this._getPendingState().muted = true;
    }
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#unMute */
  unMute() {
    if (this._player) {
      this._player.unMute();
    } else {
      this._getPendingState().muted = false;
    }
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#isMuted */
  isMuted() {
    if (this._player) {
      return this._player.isMuted();
    }
    if (this._pendingPlayerState) {
      return !!this._pendingPlayerState.muted;
    }
    return false;
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#setVolume */
  setVolume(volume) {
    if (this._player) {
      this._player.setVolume(volume);
    } else {
      this._getPendingState().volume = volume;
    }
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#getVolume */
  getVolume() {
    if (this._player) {
      return this._player.getVolume();
    }
    if (this._pendingPlayerState && this._pendingPlayerState.volume != null) {
      return this._pendingPlayerState.volume;
    }
    return 0;
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#setPlaybackRate */
  setPlaybackRate(playbackRate) {
    if (this._player) {
      return this._player.setPlaybackRate(playbackRate);
    } else {
      this._getPendingState().playbackRate = playbackRate;
    }
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#getPlaybackRate */
  getPlaybackRate() {
    if (this._player) {
      return this._player.getPlaybackRate();
    }
    if (this._pendingPlayerState && this._pendingPlayerState.playbackRate != null) {
      return this._pendingPlayerState.playbackRate;
    }
    return 0;
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#getAvailablePlaybackRates */
  getAvailablePlaybackRates() {
    return this._player ? this._player.getAvailablePlaybackRates() : [];
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#getVideoLoadedFraction */
  getVideoLoadedFraction() {
    return this._player ? this._player.getVideoLoadedFraction() : 0;
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#getPlayerState */
  getPlayerState() {
    if (!this._isBrowser || !window.YT) {
      return undefined;
    }
    if (this._player) {
      return this._player.getPlayerState();
    }
    if (this._pendingPlayerState && this._pendingPlayerState.playbackState != null) {
      return this._pendingPlayerState.playbackState;
    }
    return YT.PlayerState.UNSTARTED;
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#getCurrentTime */
  getCurrentTime() {
    if (this._player) {
      return this._player.getCurrentTime();
    }
    if (this._pendingPlayerState && this._pendingPlayerState.seek) {
      return this._pendingPlayerState.seek.seconds;
    }
    return 0;
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#getPlaybackQuality */
  getPlaybackQuality() {
    return this._player ? this._player.getPlaybackQuality() : 'default';
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#getAvailableQualityLevels */
  getAvailableQualityLevels() {
    return this._player ? this._player.getAvailableQualityLevels() : [];
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#getDuration */
  getDuration() {
    return this._player ? this._player.getDuration() : 0;
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#getVideoUrl */
  getVideoUrl() {
    return this._player ? this._player.getVideoUrl() : '';
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#getVideoEmbedCode */
  getVideoEmbedCode() {
    return this._player ? this._player.getVideoEmbedCode() : '';
  }
  /** Gets an object that should be used to store the temporary API state. */
  _getPendingState() {
    if (!this._pendingPlayerState) {
      this._pendingPlayerState = {};
    }
    return this._pendingPlayerState;
  }
  /** Initializes a player from a temporary state. */
  _initializePlayer(player, state) {
    const {
      playbackState,
      playbackRate,
      volume,
      muted,
      seek
    } = state;
    switch (playbackState) {
      case YT.PlayerState.PLAYING:
        player.playVideo();
        break;
      case YT.PlayerState.PAUSED:
        player.pauseVideo();
        break;
      case YT.PlayerState.CUED:
        player.stopVideo();
        break;
    }
    if (playbackRate != null) {
      player.setPlaybackRate(playbackRate);
    }
    if (volume != null) {
      player.setVolume(volume);
    }
    if (muted != null) {
      muted ? player.mute() : player.unMute();
    }
    if (seek != null) {
      player.seekTo(seek.seconds, seek.allowSeekAhead);
    }
  }
  /** Gets an observable that adds an event listener to the player when a user subscribes to it. */
  _getLazyEmitter(name) {
    // Start with the stream of players. This way the events will be transferred
    // over to the new player if it gets swapped out under-the-hood.
    return this._playerChanges.pipe(
    // Switch to the bound event. `switchMap` ensures that the old event is removed when the
    // player is changed. If there's no player, return an observable that never emits.
    switchMap(player => {
      return player ? fromEventPattern(listener => {
        player.addEventListener(name, listener);
      }, listener => {
        // The API seems to throw when we try to unbind from a destroyed player and it doesn't
        // expose whether the player has been destroyed so we have to wrap it in a try/catch to
        // prevent the entire stream from erroring out.
        try {
          if (player.removeEventListener) {
            player.removeEventListener(name, listener);
          }
        } catch {}
      }) : of();
    }),
    // By default we run all the API interactions outside the zone
    // so we have to bring the events back in manually when they emit.
    source => new Observable(observer => source.subscribe({
      next: value => this._ngZone.run(() => observer.next(value)),
      error: error => observer.error(error),
      complete: () => observer.complete()
    })),
    // Ensures that everything is cleared out on destroy.
    takeUntil(this._destroyed));
  }
  static {
    this.ɵfac = function YouTubePlayer_Factory(t) {
      return new (t || YouTubePlayer)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(PLATFORM_ID));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: YouTubePlayer,
      selectors: [["youtube-player"]],
      viewQuery: function YouTubePlayer_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.youtubeContainer = _t.first);
        }
      },
      inputs: {
        videoId: "videoId",
        height: "height",
        width: "width",
        startSeconds: "startSeconds",
        endSeconds: "endSeconds",
        suggestedQuality: "suggestedQuality",
        playerVars: "playerVars",
        disableCookies: "disableCookies",
        showBeforeIframeApiLoads: "showBeforeIframeApiLoads"
      },
      outputs: {
        ready: "ready",
        stateChange: "stateChange",
        error: "error",
        apiChange: "apiChange",
        playbackQualityChange: "playbackQualityChange",
        playbackRateChange: "playbackRateChange"
      },
      decls: 2,
      vars: 0,
      consts: [["youtubeContainer", ""]],
      template: function YouTubePlayer_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", null, 0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(YouTubePlayer, [{
    type: Component,
    args: [{
      selector: 'youtube-player',
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      // This div is *replaced* by the YouTube player embed.
      template: '<div #youtubeContainer></div>'
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }, {
      type: Object,
      decorators: [{
        type: Inject,
        args: [PLATFORM_ID]
      }]
    }];
  }, {
    videoId: [{
      type: Input
    }],
    height: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    startSeconds: [{
      type: Input
    }],
    endSeconds: [{
      type: Input
    }],
    suggestedQuality: [{
      type: Input
    }],
    playerVars: [{
      type: Input
    }],
    disableCookies: [{
      type: Input
    }],
    showBeforeIframeApiLoads: [{
      type: Input
    }],
    ready: [{
      type: Output
    }],
    stateChange: [{
      type: Output
    }],
    error: [{
      type: Output
    }],
    apiChange: [{
      type: Output
    }],
    playbackQualityChange: [{
      type: Output
    }],
    playbackRateChange: [{
      type: Output
    }],
    youtubeContainer: [{
      type: ViewChild,
      args: ['youtubeContainer']
    }]
  });
})();
/** Listens to changes to the given width and height and sets it on the player. */
function bindSizeToPlayer(playerObs, widthObs, heightObs) {
  return combineLatest([playerObs, widthObs, heightObs]).subscribe(([player, width, height]) => player && player.setSize(width, height));
}
/** Listens to changes from the suggested quality and sets it on the given player. */
function bindSuggestedQualityToPlayer(playerObs, suggestedQualityObs) {
  return combineLatest([playerObs, suggestedQualityObs]).subscribe(([player, suggestedQuality]) => player && suggestedQuality && player.setPlaybackQuality(suggestedQuality));
}
/**
 * Returns an observable that emits the loaded player once it's ready. Certain properties/methods
 * won't be available until the iframe finishes loading.
 * @param onAbort Callback function that will be invoked if the player loading was aborted before
 * it was able to complete. Can be used to clean up any loose references.
 */
function waitUntilReady(onAbort) {
  return mergeMap(player => {
    if (!player) {
      return of(undefined);
    }
    if (playerIsReady(player)) {
      return of(player);
    }
    // Since removeEventListener is not on Player when it's initialized, we can't use fromEvent.
    // The player is not initialized fully until the ready is called.
    return new Observable(emitter => {
      let aborted = false;
      let resolved = false;
      const onReady = event => {
        resolved = true;
        if (!aborted) {
          event.target.removeEventListener('onReady', onReady);
          emitter.next(event.target);
        }
      };
      player.addEventListener('onReady', onReady);
      return () => {
        aborted = true;
        if (!resolved) {
          onAbort(player);
        }
      };
    }).pipe(take(1), startWith(undefined));
  });
}
/** Create an observable for the player based on the given options. */
function createPlayerObservable(youtubeContainer, videoIdObs, hostObs, iframeApiAvailableObs, widthObs, heightObs, playerVarsObs, ngZone) {
  const playerOptions = combineLatest([videoIdObs, hostObs, playerVarsObs]).pipe(withLatestFrom(combineLatest([widthObs, heightObs])), map(([constructorOptions, sizeOptions]) => {
    const [videoId, host, playerVars] = constructorOptions;
    const [width, height] = sizeOptions;
    // If there's no video id or a list isn't supplied, bail out
    if (!videoId && !(playerVars?.list && playerVars?.listType)) {
      return undefined;
    }
    return {
      videoId,
      playerVars,
      width,
      height,
      host
    };
  }));
  return combineLatest([youtubeContainer, playerOptions, of(ngZone)]).pipe(skipUntilRememberLatest(iframeApiAvailableObs), scan(syncPlayerState, undefined), distinctUntilChanged());
}
/** Skips the given observable until the other observable emits true, then emit the latest. */
function skipUntilRememberLatest(notifier) {
  return pipe(combineLatest$1(notifier), skipWhile(([_, doneSkipping]) => !doneSkipping), map(([value]) => value));
}
/** Destroy the player if there are no options, or create the player if there are options. */
function syncPlayerState(player, [container, videoOptions, ngZone]) {
  if (player && videoOptions && (player.playerVars !== videoOptions.playerVars || player.host !== videoOptions.host)) {
    // The player needs to be recreated if the playerVars are different.
    player.destroy();
  } else if (!videoOptions) {
    if (player) {
      // Destroy the player if the videoId was removed.
      player.destroy();
    }
    return;
  } else if (player) {
    return player;
  }
  // Important! We need to create the Player object outside of the `NgZone`, because it kicks
  // off a 250ms setInterval which will continually trigger change detection if we don't.
  const newPlayer = ngZone.runOutsideAngular(() => new YT.Player(container, videoOptions));
  newPlayer.videoId = videoOptions.videoId;
  newPlayer.playerVars = videoOptions.playerVars;
  newPlayer.host = videoOptions.host;
  return newPlayer;
}
/**
 * Call cueVideoById if the videoId changes, or when start or end seconds change. cueVideoById will
 * change the loaded video id to the given videoId, and set the start and end times to the given
 * start/end seconds.
 */
function bindCueVideoCall(playerObs, videoIdObs, startSecondsObs, endSecondsObs, suggestedQualityObs, destroyed) {
  const cueOptionsObs = combineLatest([startSecondsObs, endSecondsObs]).pipe(map(([startSeconds, endSeconds]) => ({
    startSeconds,
    endSeconds
  })));
  // Only respond to changes in cue options if the player is not running.
  const filteredCueOptions = cueOptionsObs.pipe(filterOnOther(playerObs, player => !!player && !hasPlayerStarted(player)));
  // If the video id changed, there's no reason to run 'cue' unless the player
  // was initialized with a different video id.
  const changedVideoId = videoIdObs.pipe(filterOnOther(playerObs, (player, videoId) => !!player && player.videoId !== videoId));
  // If the player changed, there's no reason to run 'cue' unless there are cue options.
  const changedPlayer = playerObs.pipe(filterOnOther(combineLatest([videoIdObs, cueOptionsObs]), ([videoId, cueOptions], player) => !!player && (videoId != player.videoId || !!cueOptions.startSeconds || !!cueOptions.endSeconds)));
  merge(changedPlayer, changedVideoId, filteredCueOptions).pipe(withLatestFrom(combineLatest([playerObs, videoIdObs, cueOptionsObs, suggestedQualityObs])), map(([_, values]) => values), takeUntil(destroyed)).subscribe(([player, videoId, cueOptions, suggestedQuality]) => {
    if (!videoId || !player) {
      return;
    }
    player.videoId = videoId;
    player.cueVideoById({
      videoId,
      suggestedQuality,
      ...cueOptions
    });
  });
}
function hasPlayerStarted(player) {
  const state = player.getPlayerState();
  return state !== YT.PlayerState.UNSTARTED && state !== YT.PlayerState.CUED;
}
function playerIsReady(player) {
  return 'getPlayerStatus' in player;
}
/** Combines the two observables temporarily for the filter function. */
function filterOnOther(otherObs, filterFn) {
  return pipe(withLatestFrom(otherObs), filter(([value, other]) => filterFn(other, value)), map(([value]) => value));
}
const COMPONENTS = [YouTubePlayer];
class YouTubePlayerModule {
  static {
    this.ɵfac = function YouTubePlayerModule_Factory(t) {
      return new (t || YouTubePlayerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: YouTubePlayerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(YouTubePlayerModule, [{
    type: NgModule,
    args: [{
      declarations: COMPONENTS,
      exports: COMPONENTS
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { YouTubePlayer, YouTubePlayerModule };
